body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "intelone-mono-font";
  src: local("intelone-mono-font"),
    url(./assets/fonts/intelone-mono-font-family-regular.woff2) format("woff2");
}

.intelone {
  font-family: "intelone-mono-font";
}

.custom-img {
  background-image: url(./assets/images/hero.webp);
}

.custom-img-1 {
  background-image: url(./assets/images/Block1.webp);
}

.custom-img-white {
  background-image: url(./assets/images/white.webp);
}

.custom-img-5 {
  background-image: url(./assets/images/Block5.webp);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.h-screen-50 {
  height: 50vh;
}

#aboutGSAP {
  opacity: 0;
}

.wrapper1 {
  display: grid;
  height: 50vh;
  place-items: center
}

.title1 {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.54);
  color: transparent;
  pointer-events: none;
}

.title2 {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.54);
  color: transparent;
  pointer-events: none;
}

.title21 {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.54);
  color: transparent;
  pointer-events: none;
}

.title22 {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.54);
  color: transparent;
  pointer-events: none;
}

.title211 {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.54);
  color: transparent;
  pointer-events: none;
}

.hero__h1 {
  height: 5vh;
  overflow: hidden;
  opacity: 0.5;
}

.hero__h2 {
  height: 5vh;
  margin-top: -13px !important;
  overflow: hidden;
  line-height: 1;
}

.hero__h3 {
  overflow: hidden;
  margin-top: -17px !important;
  line-height: 1;
}

.block__h1 {
  height: 5vh;
  overflow: hidden;
  margin-bottom: 2px;
  opacity: 0.5;
}

.block__h2 {
  height: 5vh;
  overflow: hidden;
  margin-top: -13px !important;
  line-height: 1;
}

.hero__h3 {}

.title3 {
  text-shadow: -1px -1px 0 rgb(255, 0, 0), 1px -1px 0 rgb(255, 0, 0), -1px 1px 0 rgb(255, 0, 0), 1px 1px 0 rgb(255, 0, 0);
}

.bg-lpole-800 {
  background-color: #303030;
}

h1 .glitch {
  color: #000000;
  font-size: 5em;
  letter-spacing: 8px;
  font-family: "Press Start 2P";
  font-weight: 400;
  /*Create overlap*/

  margin: 0;
  line-height: 0;
  /*Animation*/

  animation: glitch1 2.5s infinite;
}

h1:nth-child(2) .glitch {
  color: #67f3da;
  animation: glitch2 2.5s infinite;
}

h1:nth-child(3) .glitch {
  color: #f16f6f;
  animation: glitch3 2.5s infinite;
}

/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }

  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  27% {
    transform: none;
    opacity: 1;
  }

  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }

  35% {
    transform: none;
    opacity: 1;
  }

  52% {
    transform: none;
    opacity: 1;
  }

  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }

  50% {
    transform: none;
    opacity: 1;
  }

  72% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }

  80% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}